import { useEffect, useState } from 'react';
import { UilAngleUp, UilAngleDown, UilBackspace, UilPlus, UilEditAlt, UilTrash } from '@iconscout/react-unicons';
import './customselect.scss';
import { Tooltip } from 'react-tooltip';

export function CustomSelect(props) {
  const [expanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (props.selected) setSelectedItem(props.selected);
  }, [props.selected]);

  useEffect(() => {
    let selectedInOption = false;
    props?.options?.forEach((option) => {
      if (option.value === selectedItem?.value) {
        selectedInOption = true;
      }
    });
  }, [props?.options]);

  function edit(item) {
    if (props.edit) props.edit(item);
  }

  function deleteItem(item) {
    setSelectedItem(props?.default);
    if (props.onDelete) props.onDelete(item);
  }

  return (
    <div
      className="csCustomSelect"
      style={props.style}
      onBlur={() => setExpanded(false)}
    >
      <div
        onClick={() => setExpanded(!expanded)}
        className="csCustomSelectLabel"
      >
        <div>{props.icon}</div>
        <span className={selectedItem?.label && 'selected'}>
          {selectedItem?.label || props.default.label}
        </span>
        <div className="arrow">
          {expanded ? <UilAngleUp /> : <UilAngleDown />}
        </div>
      </div>
      {expanded && (
        <div className="csCustomSelectExpanded">
          {props.options.map((option) => (
            <div className="csCustomSelectOption" key={option.value}>
              <div style={{ display: 'flex' }}>
                <div
                  id={`tt-${option.value}`}
                  onClick={() => {
                    setExpanded(false);
                    setSelectedItem(option);
                    if (props.onChange) props.onChange(option || props.default);
                    setExpanded(false);
                  }}
                  className="csCustomSelectedOption"
                >
                  {option?.label}
                </div>
                <Tooltip place='right' anchorSelect={`#tt-${option.value}`} style={{ zIndex: 9999 }}>{option?.label}</Tooltip>
                {props.default.value !== option.value && props.edit && (
                  <span
                    className="csCustomOptionEdit edit"
                    style={{ marginLeft: 'auto', marginTop: 4 }}
                    onClick={() => {
                      edit(option.value);
                      setExpanded(false);
                    }}
                  >
                    <UilEditAlt />
                  </span>
                )}
                {props.default.value !== option.value && props.onDelete && (
                  <span
                    className="csCustomOptionEdit trash"
                    style={{ marginLeft: 'auto', marginTop: 4 }}
                    onClick={() => {
                      deleteItem(option.value);
                      if (!props?.dontCollapseOnDelete) setExpanded(false);
                    }}
                  >
                    <UilTrash />
                  </span>
                )}
              </div>
            </div>
          ))}
          <hr style={{ marginTop: 5, marginBottom: 5 }} />
          <div className="csCustomSelectActions">
            {!props.hideClear && (
              <span
                className="csCustomSelectBottomAction rounded"
                onClick={() => {
                  setSelectedItem(null);
                  setExpanded(false);
                  if (props.customClearAction) props.customClearAction();
                }}
              >
                <UilBackspace /> Clear
              </span>
            )}
            <span
              className="csCustomSelectBottomAction rounded"
              onClick={() => {
                if (props.new) {
                  props.new();
                  setExpanded(false);
                }
              }}
            >
              <UilPlus /> New
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
