/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import { AuthContext, Loader, useAPI } from 'components/lib';
import { useState, useContext } from 'react';
import { Row, Col } from 'react-grid-system';
import {
  UilCog,
  UilUserCircle,
  UilAsterisk,
  UilShield,
  UilUsdCircle,
  UilBoltAlt,
  UilKeySkeletonAlt,
  UilUsersAlt,
  UilBuilding
} from '@iconscout/react-unicons';
import { NavLink, useLocation } from 'react-router-dom';
import { Profile } from './profile';
import { Password } from './password';
// import {Upgrade} from "./upgrade";
import { Users } from './users';
import { TwoFA } from './2fa';
import { Billing } from './billing';
import { Integration } from './integrations';
import { APIKeyList } from './apikey/list';
import './index.scss';
import { useEffect } from 'react';
import { Workspace } from './workspace';
import { Whitelabel } from './whitelabel';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

export function Account(props) {
  const sidebarItems = [
    {
      title: 'Account',
      icon: <UilUserCircle />,
      navigateTo: '/account/profile',
      component: <Profile />,
    },
    {
      title: 'Workspaces',
      icon: <UilBuilding />,
      navigateTo: '/account/workspace',
      component: <Workspace />,
    },
    {
      title: 'Whitelabel',
      icon: <UilCog />,
      navigateTo: '/account/whitelabel',
      component: <Whitelabel />,
    },
    {
      title: 'Password',
      icon: <UilAsterisk />,
      navigateTo: '/account/password',
      component: <Password />,
    },
    {
      title: '2FA',
      icon: <UilShield />,
      navigateTo: '/account/2fa',
      component: <TwoFA />,
    },
    {
      title: 'Billing',
      icon: <UilUsdCircle />,
      navigateTo: '/account/billing',
      component: <Billing />,
    },
    {
      title: 'Integrations',
      icon: <UilBoltAlt />,
      navigateTo: '/account/integration',
      component: <Integration />,
    },
    {
      title: 'API Keys',
      icon: <UilKeySkeletonAlt />,
      navigateTo: '/account/apikeys',
      component: <APIKeyList />,
    },
    {
      title: 'Users',
      icon: <UilUsersAlt />,
      navigateTo: '/account/users',
      component: <Users />,
    },
  ];
  const location = useLocation();
  const context = useContext(AuthContext);
  const user = useAPI('/api/user');
  const [selectedTab, setSelectedTab] = useState('Profile');
  const deviceWidth = useWindowResize();

  useEffect(() => {
    if (location.pathname === '/account' || location.pathname === '/account/') setSelectedTab('account-navigation');
    else {
      const [{ title }] = sidebarItems.filter(
        ({ navigateTo }) =>
          navigateTo === location.pathname ||
          location.pathname.includes(navigateTo)
      );
      setSelectedTab(title);
    }
  }, [location]);

  if (user.loading) return <Loader />;

  return (
    <div className="account">
      <Row>
        <Col xs={12} lg={2} style={{ display: deviceWidth > 991 ? 'block' : selectedTab === 'account-navigation' ? 'block' : 'none' }}>
          <div className="sidebar-items">
            {sidebarItems.map(({ title, icon, navigateTo }) => {
              if (title === 'Whitelabel' && context.user.permission !== 'owner') return <></>;
              if (title === 'Billing' && context.user.permission === 'client') return <></>;
              return (
                <NavLink key={title} to={navigateTo}>
                  <div className="sidebar-item">
                    <div className="icon">{icon}</div>
                    <div className="title">{title}</div>
                  </div>
                </NavLink>
              )
            })}
          </div>
        </Col>
        <Col className='hide-in-mobile' xs={0} lg={0}>
          <div className="divider"></div>
        </Col>
        {selectedTab !== 'account-navigation' && <Col xs={12} lg={9.5}>
          {sidebarItems.find(({ title }) => title === selectedTab)?.component}
        </Col>}
      </Row>
    </div>
  );
}
