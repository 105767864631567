import Axios from 'axios';
import {
  Button,
  Checkbox,
  Loader,
  Radio,
  TextInput,
  UpgradePlan,
  useAPI,
  useRouteCheck,
  WhitelabelContext,
} from 'components/lib';
import CustomButton from 'components/custom/customButton';
import {
  UilUpload,
  UilMapPin,
  UilCheck,
  UilSubject,
  UilQuestionCircle
} from '@iconscout/react-unicons';
import Steps from 'components/steps/steps';
import Papa from 'papaparse';
import { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Map from 'views/leads/import/map';
import Upload from 'views/leads/import/upload';
import verifyCheck from '../../../assets/verify_check.png';
import './import.scss';
import { gapi } from 'gapi-script';
import { Helmet } from 'react-helmet';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const STEPS = ['Lead Source', 'Map', 'Verify', 'Details'];

const systemValues = {
  First_Name: true,
  Last_Name: true,
  Email: true,
  Phone: true,
  Company_Name: true,
  Office_Phone: true,
  Job_Title: true,
  Department: true,
  Street: true,
  City: true,
  State: true,
  Country: true,
  Website: true,
  skip: true,
}

export default function Import() {
  const auth = useAPI('/api/auth');
  const allowRoute = useRouteCheck(
    window.location.pathname,
    auth.data?.plan,
    auth.data?.subscription,
    auth.data?.expired
  );
  let { list_id } = useParams();
  let navigator = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [verifyContacts, setVerifyContacts] = useState(false);
  const [archiveInvalidContacts, setArchiveInvalidContacts] = useState(false);
  const [archiveRiskyContacts, setArchiveRiskyContacts] = useState(false);
  const [columnsOrder, setColumnsOrder] = useState([]);
  const [mappedValues, setMappedValues] = useState([]);
  const [dataHeader, setDataHeader] = useState([]);
  const [listName, setListName] = useState('');
  const [fieldNames, setFieldNames] = useState([
    { label: 'Skip Column', value: 'skip' },
    { label: 'First Name', value: 'First_Name' },
    { label: 'Last Name', value: 'Last_Name' },
    { label: 'Email', value: 'Email' },
    { label: 'Phone', value: 'Phone' },
    { label: 'Company Name', value: 'Company_Name' },
    { label: 'Office Phone', value: 'Office_Phone' },
    { label: 'Job Title', value: 'Job_Title' },
    { label: 'Department', value: 'Department' },
    { label: 'Street', value: 'Street' },
    { label: 'City', value: 'City' },
    { label: 'State', value: 'State' },
    { label: 'Country', value: 'Country' },
    { label: 'Website', value: 'Website' },
  ]);
  const [mapError, setMapError] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [existingList, setexistingList] = useState(null);
  const [removeDuplicated, setRemoveDuplicated] = useState(false);
  const [removeDuplicatedFromThisList, setRemoveDuplicatedFromThisList] =
    useState(false);
  const [removeDuplicatedFromOtherList, setRemoveDuplicatedFromOtherList] =
    useState(false);
  const [
    removeDuplicatedFromTeamMemberList,
    setRemoveDuplicatedFromTeamMemberList,
  ] = useState(false);

  const [importType, setImportType] = useState('csv');
  const deviceWidth = useWindowResize();

  //get data from google sheets
  const [sheetUrl, setSheetUrl] = useState('');
  const [lastRowFetched, setLastRowFetched] = useState(0);
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENTID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const SCOPES = 'https://www.googleapis.com/auth/spreadsheets.readonly';
  const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';

  const { whitelabelDetails } = useContext(WhitelabelContext);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: [DISCOVERY_DOC],
        scope: SCOPES,
      }).then(() => {
        console.log('GAPI client initialized.');
      }, (error) => {
        console.error('Error initializing GAPI client:', error);
      });
    };
    gapi.load('client:auth2', initClient);
  }, [API_KEY, CLIENT_ID]);

  const handleAuthClick = () => {
    if (gapi.auth2) {
      const authInstance = gapi.auth2.getAuthInstance();
      if (authInstance) {
        authInstance.signIn().then(() => {
          listMajors();
        }).catch((error) => {
          console.error('Error signing in:', error);
        });
      } else {
        console.error('Error: gapi.auth2.getAuthInstance() returned null.');
      }
    } else {
      console.error('Error: gapi.auth2 is not available.');
    }
  };

  const listMajors = async () => {
    try {
      if(!validateGoogleSpreadsheetLink(sheetUrl)) {
        throw new Error('Invalid Spreadsheet Link');
      }
      const sheetId = sheetUrl.split('/d/')[1].split('/')[0];
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: sheetId,
        range: 'A:Z',
      });
      const data = convertToCSV(response.result.values);
      const blob = new Blob([data], { type: 'text/csv' });
      setSelectedFile(blob);
    } catch (err) {
      setSheetUrl('');
      const emptyListRegex = /Cannot read properties of undefined/i;
      let title = 'Error in Link';
      let text = 'Please make sure your Google Sheet link is viewable by everyone.';
      if (emptyListRegex.test(err.message)) {
        title = 'Error in Sheet';
        text = 'Please make sure “Email” column exists in the sheet.';
      }
      Swal.fire({
        icon: 'error',
        title: title,
        text: text,
        html: '',
        showCancelButton: false,
        confirmButtonText: `Okay`,
        confirmButtonColor: '#0066ff',
      });
    }
  };

  const convertToCSV = (data) => {
    return data.map(row => row.map(cell => `"${cell}"`).join(',')).join('\n');
  };

  useEffect(() => {
    if (list_id !== 'new') {
      getList(list_id).then((list) => {
        let completeViewList = list.views.find((view) => view.name === 'Complete View')
        let columns = []
        let newFieldNames = fieldNames
        if(completeViewList) columns = completeViewList.columns
        if(columns.length > 0) {
          for (let item of columns) {
            const exists = newFieldNames.some((field) => field.value === item);
            if(!exists) {
              newFieldNames.push({
                label: item.replaceAll('_', ' '),
                value: item,
              })
            }
          }
          setFieldNames(newFieldNames)
        }
        // console.log('Got List ', newFieldNames);
        if (list?.views?.length > 0) {
          setRemoveDuplicated(true);
          setRemoveDuplicatedFromThisList(true);
        }
        setListName(list.name);
        setexistingList(list);
      });
    }
  }, [list_id]);

  // set the name of the existing list

  useEffect(() => {
    if (!selectedFile) return;
    parseCsvFile()
  }, [selectedFile]);

  function parseCsvFile() {
    const expectedDelimiter = ","
    const reader = new FileReader();

    // Read the content of the file
    reader.onload = (event) => {
      const content = event.target.result;
      const emailRegex = /email/i;
      // Check if the expected delimiter is present in the content
      if (content.includes(expectedDelimiter) || emailRegex.test(content)) {
        // Continue with parsing if the expected delimiter is found
        Papa.parse(content, {
          header: true,
          skipEmptyLines: true,
          delimiter: expectedDelimiter,
          complete: function (results) {
            const rowsArray = [];
            const valuesArray = [];

            // Iterating data to get column name and their values
            results.data.map((d) => {
              rowsArray.push(Object.keys(d));
              valuesArray.push(Object.values(d));
            });

            // Parsed Data Response in array format
            setParsedData(results.data);

            // Filtered Column Names
            setTableRows(rowsArray[0]);
            // console.log("ROW ", rowsArray[0])

            if (rowsArray[0] === undefined) {
              setSelectedFile(null)
              Swal.fire({
                icon: 'info',
                title: 'Please upload CSV file with some data in it!',
                html: '',
                showCancelButton: false,
                confirmButtonText: `OK`,
                confirmButtonColor: '#0066FF',
              });
              return;
            }
            let preSelect = []
            for(let i of rowsArray[0]) {
              let existField = fieldNames.find(f => f.value === i.replaceAll(' ', '_'))
              if(existField) preSelect.push(existField.value)
              else preSelect.push('')
            }
            setMappedValues(preSelect);
            setDataHeader(rowsArray[0])

            // Filtered Values
            setValues(valuesArray);
            setLastRowFetched(results.data.length);
          },
        });
      } else {
        // Trigger an error if the expected delimiter is not found
        setSelectedFile(null)
        Swal.fire({
          icon: 'info',
          title: 'Please upload CSV file with "," delimiter!',
          html: '',
          showCancelButton: false,
          confirmButtonText: `OK`,
          confirmButtonColor: '#0066FF',
        });
      }
    };

    reader.readAsText(selectedFile);
  }

  function prevStep() {
    setCurrentStep((oldCurrentStep) => oldCurrentStep - 1);
  }

  function nextStep(acceptedFiles) {
    if (STEPS[currentStep] === 'Details') {
      if (listName === '') {
        return Swal.fire({
          icon: 'info',
          title: 'Please enter a name for the list!',
          html: '',
          showCancelButton: false,
          confirmButtonText: `OK`,
          confirmButtonColor: '#0066FF',
        });
      }
      /* Saving the list to the database. */
      if (existingList) {
        updateExistingList();
      } else {
        saveNewList();
      }
      return;
    }
    if (STEPS[currentStep] === 'Map') {
      let isEmpty = false;
      let emailMarked = false;
      let columns = {};
      // Check Columns
      columnsOrder.forEach((col) => {
        if (col === 'Email') {
          emailMarked = true;
        }
        if (col === '') {
          isEmpty = true;
          Swal.fire({
            icon: 'info',
            title: 'Please map all fields!',
            html: '',
            focusConfirm: false,
            // skip unmapped fields button
            showConfirmButton: true,
            confirmButtonColor: 'grey',
            confirmButtonText: 'Skip Unmapped Fields',
            // cancel button
            showCancelButton: true,
            cancelButtonColor: '#0066FF',
            cancelButtonText: 'OK',
            reverseButtons: true,
          }).then((answer) => {
            // if user clicks on skip unmapped fields buton
            if (answer.isConfirmed) {
              setColumnsOrder((previousState) => {
                for (const [index, property] of previousState.entries()) {
                  // add skip to every unmapped field
                  previousState[index] = !property ? 'skip' : property;
                }
                setMappedValues([...previousState]);
                return [...previousState];
              });
            }
          });
        }
        if (col !== '' && col !== 'skip') {
          if (columns[col]) columns[col] += 1;
          else columns[col] = 1;
        }
      });
      if (emailMarked === false) {
        Swal.fire({
          icon: 'info',
          title: 'Please map email field.',
          html: '',
          showCancelButton: false,
          confirmButtonText: `OK`,
          confirmButtonColor: '#0066FF',
        });
        return;
      }
      if (isEmpty) return;
      for (var key in columns) {
        if (columns.hasOwnProperty(key) && columns[key] > 1) {
          setMapError(key);
          Swal.fire({
            icon: 'info',
            title: `Duplicate ${key.replace('_', ' ')} field not allowed.`,
            html: '',
            showCancelButton: false,
            confirmButtonText: `Ok`,
            confirmButtonColor: '#0066ff',
          });
          return;
        }
      }
    }
    if (STEPS[currentStep] === 'Lead Source') {
      if (!selectedFile) {
        Swal.fire({
          icon: 'error',
          title: 'Oops!',
          text: 'Please upload a CSV or paste Google Sheet link to continue.',
          html: '',
          showCancelButton: false,
          confirmButtonText: `Okay`,
          confirmButtonColor: '#0066ff',
        });
        return;
      }
      if (parsedData.length > 100000) {
        Swal.fire({
          icon: 'info',
          title: 'Max 1,00,000 contact can be in list!',
          html: '',
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        });
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  }

  function validateGoogleSpreadsheetLink(url) {
    const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+).*$/;
    const result = regex.test(url);
    if(result) {
      return true;
    } else {
      setSheetUrl('');
      Swal.fire({
        icon: 'error',
        title: 'Error in Link',
        text: 'Please make sure your Google Sheet link is viewable by everyone.',
        html: '',
        showCancelButton: false,
        confirmButtonText: `Okay`,
        confirmButtonColor: '#0066ff',
      });
      return false;
    }
  }

  async function saveNewList() {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('csvFile', selectedFile);
      formData.append('name', listName);
      formData.append('verification_required', verifyContacts);
      formData.append('archive_invalid_contacts', archiveInvalidContacts);
      formData.append('archive_risky_contacts', archiveRiskyContacts);
      formData.append('last_modified', new Date().toISOString());
      formData.append('fields_order', JSON.stringify(columnsOrder.map(column => column.replace(/ /g, "_"))));
      if (removeDuplicatedFromThisList)
        formData.append('remove_duplicate_from_this_list', true);
      if (removeDuplicatedFromOtherList)
        formData.append('remove_duplicate_id_exist_in_other_list', true);
      if (removeDuplicatedFromTeamMemberList)
        formData.append('remove_duplicate_id_exist_in_team_list', true);

      if(sheetUrl && validateGoogleSpreadsheetLink(sheetUrl)) {
        const sheetId = sheetUrl.split('/d/')[1].split('/')[0];
        formData.append('google_spreadsheet', sheetId);
        formData.append('google_spreadsheet_last_checked', new Date().getTime());
        formData.append('google_spreadsheet_last_checked_row', lastRowFetched);
      }

      let result = await Axios({
        method: 'POST',
        url: '/api/list',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (result.data.success) {
        navigator('/outreach/lists');
      }
    } catch (err) {
      console.log('Error in saving list ', err);
      Toast.fire({
        icon: 'error',
        title: err?.response?.data?.err || 'Unable to import list.',
      });
    } finally {
      setLoading(false);
    }
  }

  async function addLeads(leads, first, last) {
    try {
      var formData = new FormData();
      formData.append('name', listName);
      formData.append('leads', JSON.stringify(leads));
      formData.append('first', first);
      formData.append('verification_required', verifyContacts);
      formData.append('archive_invalid_contacts', archiveInvalidContacts);
      formData.append('archive_risky_contacts', archiveRiskyContacts);
      formData.append('last_modified', new Date().toISOString());
      formData.append('fields_order', JSON.stringify(columnsOrder));
      formData.append('list_id', existingList.id);
      formData.append('previous_contacts_count', existingList.contacts_count);
      if (removeDuplicatedFromThisList)
        formData.append('remove_duplicate_from_this_list', true);
      if (removeDuplicatedFromOtherList)
        formData.append('remove_duplicate_id_exist_in_other_list', true);
      if (removeDuplicatedFromTeamMemberList)
        formData.append('remove_duplicate_id_exist_in_team_list', true);

      let result = await Axios({
        method: 'PATCH',
        url: '/api/list',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function updateExistingList() {
    setPageLoading(true)
    try {
      const batchSize = 500;
      
      const fieldsOrder = columnsOrder.map(column => column.replace(/ /g, "_")).map(field => {
        if(systemValues[field]) return field;
        return field.toUpperCase();
      })

      let first = true;

      for (let i = 0; i < parsedData.length; i += batchSize) {
        // Get the current batch of 500 items
        const batch = parsedData.slice(i, i + batchSize);
        
        const formattedBatch = batch.map((row) => {
          let formattedRow = {};
          
          fieldsOrder.forEach((field, index) => {
            if (field !== "skip") {
              formattedRow[field] = row[dataHeader[index]];
            }
          });
          
          return formattedRow; // Return the formatted row with only the required fields
        });

        // console.log("FORMATTED BATCH ", formattedBatch);
        await addLeads(formattedBatch, first)
        first = false;
      }

      setTimeout(() => {
        navigator('/outreach/lists');
      }, 6000)
    } catch (err) {
      setPageLoading(false)
      console.log('Error in saving list ', err);
      Toast.fire({
        icon: 'error',
        title: err?.response?.data?.err || 'Unable to import list.',
      });
    }
  }

  async function getList(list_id) {
    try {
      let result = await Axios({
        method: 'GET',
        url: '/api/list/' + list_id,
      });
      return result.data.data;
    } catch (err) {
      console.log('Error in saving list ', err);
      Toast.fire({ icon: 'error', title: 'Error in finding existing list' });
      navigator('/outreach/lists');
    }
  }

  if (!allowRoute) {
    if (auth.loading) return <Loader />;
    else return <UpgradePlan />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Import List | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      {pageLoading && <div
        className="csFormLoader"
        style={{
          background: `rgba(255, 255, 255, 0.8)`,
          marginTop: 80,
          height: "90vh"
        }}
      >
        <Loader>
          <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
            <p className='text-xl'>Uploading Leads</p>
            <p className='mt-2'>This can take a while depending on number of leads.</p>
          </div>
        </Loader>
      </div>}
      <div>
        <Steps
          current={STEPS[currentStep]}
          steps={[
            {
              step: 'Lead Source',
              image: <UilUpload size={30} />,
              accent: {
                color: '#8929E0',
                background: '#E3CCF8',
                outline: '#B67AEC',
              },
            },
            {
              step: 'Map',
              image: <UilMapPin size={30} />,
              accent: {
                color: '#03A6FF',
                background: '#EAF1F7',
                outline: '#93CCF3',
              },
            },
            {
              step: 'Verify',
              image: <UilCheck size={30} />,
              accent: {
                color: '#3AC569',
                background: '#D3EFDC',
                outline: '#89DCA5',
              },
            },
            {
              step: 'Details',
              image: <UilSubject size={30} />,
              accent: {
                color: '#ED317F',
                background: '#FBD6E5',
                outline: '#F484B2',
              },
            },
          ]}
        />
      </div>

      {STEPS[currentStep] === STEPS[0] && (
        <>
          {selectedFile ? (
            <div
              className="import-file-details"
              style={{ textAlign: 'center' }}
            >
              <h2>{selectedFile?.name}</h2>
              <p>{parsedData.length} rows found</p>
              <button
                onClick={() => {
                  setSelectedFile(null);
                }}
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              >
                Remove
              </button>
            </div>
          ) : (
            <div className='w-full'>
              <h2 style={{ textAlign: 'center', fontSize: 24, marginTop: 40, marginBottom: 10, fontWeight: 500 }}>
                Add your leads
              </h2>
              <div className='flex flex-col gap-4 justify-center text-center'>
                <p style={{ textAlign: 'center', fontSize: 16 }}>
                  Either upload a CSV file or use a public Google Sheets link to import leads.
                </p>
              </div>
              <div className='flex justify-center row-column-responsive-list'>
                <div className='flex' style={{ cursor: 'pointer' }} onClick={() => setImportType('csv')}>
                  <Radio
                    name="csv"
                    checked={importType === 'csv'}
                    onChange={() => setImportType('csv')}
                  />
                  <span>Upload via CSV</span>
                </div>
                <div className='flex' style={{ cursor: 'pointer' }} onClick={() => setImportType('googleSheet')}>
                  <Radio
                    name="googleSheet"
                    checked={importType === 'googleSheet'}
                    onChange={() => setImportType('googleSheet')}
                  />
                  <span>Upload via Google Sheet</span>
                </div>
              </div>
              {importType === 'csv' && 
                <Upload
                  onSelected={(acceptedFiles) => {
                    if (
                      acceptedFiles[0].type !== 'text/csv' &&
                      acceptedFiles[0].type !== 'application/vnd.ms-excel'
                    )
                      return Swal.fire({
                        icon: 'error',
                        title: 'Please select a .csv file!',
                        html: '',
                        showCancelButton: false,
                        confirmButtonText: `Ok`,
                        confirmButtonColor: '#0066ff',
                      });
                    setSheetUrl('');
                    setSelectedFile(acceptedFiles[0]);
                  }}
                  type="list"
                  paddingZero={true}
                />
              }
              {importType === 'googleSheet' &&
                <div id='google-sheet-input' style={{ margin: 'auto', fontWeight: 500, marginTop: '2rem' }} className='flex flex-col gap-4 justify-center text-center max-w-[500px]'>
                  <div className='flex gap-4 justify-center items-start'>
                    <label style={{ display: "flex", fontSize: '1.5rem' }}>
                      Public Google Sheet link
                      <UilQuestionCircle size={18} style={{ marginLeft: 3, marginTop: 2, cursor: 'pointer' }} id="select-email-sender" />
                    </label>
                    <Tooltip anchorSelect="#select-email-sender" place="right">
                      Paste a Google Sheet link with lead details & make sure it is viewable by anyone.
                    </Tooltip>
                  </div>
                  <input
                    className='mt-4 p-3 rounded-md border border-black text-center'
                    type='text'
                    value={sheetUrl}
                    onChange={(e) => setSheetUrl(e.target.value)}
                    placeholder='Paste your Google Sheet link here'
                  />
                  <Button style={{marginTop: '1rem'}} text={'Import'} variant={'secondary'} action={listMajors} />
                </div>
              }
            </div>
          )}
        </>
      )}
      {STEPS[currentStep] === STEPS[1] && (
        <Map
          fieldNames={fieldNames}
          onChange={(cols) => setColumnsOrder(cols)}
          columnsOrder={columnsOrder}
          columns={tableRows}
          mappedValues={mappedValues}
          setMappedValues={setMappedValues}
          sample={values[0]}
          mapError={mapError}
          setMapError={setMapError}
          removeDuplicated={removeDuplicated}
          setRemoveDuplicated={setRemoveDuplicated}
          removeDuplicatedFromThisList={removeDuplicatedFromThisList}
          setRemoveDuplicatedFromThisList={setRemoveDuplicatedFromThisList}
          removeDuplicatedFromOtherList={removeDuplicatedFromOtherList}
          setRemoveDuplicatedFromOtherList={setRemoveDuplicatedFromOtherList}
          removeDuplicatedFromTeamMemberList={
            removeDuplicatedFromTeamMemberList
          }
          setRemoveDuplicatedFromTeamMemberList={
            setRemoveDuplicatedFromTeamMemberList
          }
        />
      )}
      {STEPS[currentStep] === STEPS[2] && (
        <div style={{ textAlign: 'center', color: '#3A3A3A' }}>
          <h2
            style={{
              textAlign: 'center',
              fontSize: 22,
              marginTop: 30,
              fontWeight: 500,
            }}
          >
            Verify Emails in your list
          </h2>
          <p style={{ width: deviceWidth > 991 ? 1000 : '', textAlign: 'center', margin: 'auto' }}>
            Reduce bounces by verifying the emails of your leads.
          </p>
          <img
            alt="Verify Emails Check Mark"
            style={{ margin: 'auto', height: 300 }}
            src={verifyCheck}
          />
          <div
            style={{
              display: 'flex',
              width: 300,
              justifyContent: 'center',
              margin: 'auto',
            }}
          >
            <Checkbox
              name="verifyList"
              onChange={(i, checked, option) => {
                if(verifyContacts) {
                  setArchiveInvalidContacts(false)
                  setArchiveRiskyContacts(false)
                }
                setVerifyContacts(!verifyContacts);
              }}
              checked={verifyContacts}
            />
            <b>Verify Emails of all leads in list</b>
          </div>
        
          {verifyContacts && <div className='mt-2'>
            <div style={{ display: "flex", gap: "0.25rem", alignItems: "baseline", justifyContent: 'center', margin: 'auto' }}>
              <Checkbox
                name="archive_invalid_contacts"
                onChange={(i, checked, option) =>
                  setArchiveInvalidContacts(!archiveInvalidContacts)
                }
                checked={archiveInvalidContacts}
                option="Archive leads with Invalid Email"
              />
              <UilQuestionCircle data-tooltip-show="true" data-tooltip-content="Any lead with invalid email will be archived automatically you can access them from Archived."/>
            </div>
            <div style={{ display: "flex", gap: "0.25rem", alignItems: "baseline", justifyContent: 'center', margin: 'auto' }}>
              <Checkbox
                name="archive_risky_contacts"
                onChange={(i, checked, option) =>
                  setArchiveRiskyContacts(!archiveRiskyContacts)
                }
                checked={archiveRiskyContacts}
                option="Archive leads with Risky Email"
              />
              <UilQuestionCircle data-tooltip-show="true" data-tooltip-content="Any lead with a risky email will be archived automatically, you can access them from Archived."/>
            </div>
            <Tooltip place='top' anchorSelect='[data-tooltip-show="true"]'/>
          </div>}
        </div>
      )}
      {STEPS[currentStep] === STEPS[3] && !existingList && (
        <div style={{ color: '#3A3A3A' }}>
          <h2
            style={{
              textAlign: 'center',
              fontSize: 22,
              marginTop: 30,
              fontWeight: 500,
            }}
          >
            Name your list
          </h2>
          <p style={{ width: deviceWidth > 991 ? 1000 : '', textAlign: 'center', margin: 'auto' }}>
            Used to identify your list while launching a sequence.
          </p>
          <div className="csRow">
            <div style={{ width: 900, margin: 'auto' }}>
              <span
                style={{ padding: 8, display: 'block', fontWeight: 'bold' }}
              >
                List Name
              </span>
              <TextInput
                placeholder="Enter a name for your list."
                value={listName}
                onChange={(a, val, c) => {
                  setListName(val);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {STEPS[currentStep] === STEPS[3] && existingList && (
        <div>
          <h2 style={{ textAlign: 'center', fontSize: 22, marginTop: 30 }}>
            Adding Contacts to your existing list
          </h2>
          <p style={{ width: '1000px', textAlign: 'center', margin: 'auto' }}>
            Used as an identifier while launching outreach sequences.
          </p>
          <div className="csRow">
            <div style={{ width: 900, margin: 'auto' }}>
              <span
                style={{ padding: 8, display: 'block', fontWeight: 'bold' }}
              >
                List Name
              </span>
              <TextInput
                placeholder="Update your List Name"
                value={listName}
                onChange={(a, val, c) => {
                  setListName(val);
                }}
              />
            </div>
          </div>
        </div>
      )}

      <br />
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            width: 300,
            justifyContent: 'center',
            gap: '2rem',
            margin: 'auto',
          }}
        >
          <CustomButton
            title="Cancel"
            variant="secondary red"
            onClick={() => navigator('/outreach/lists')}
          />

          {currentStep !== 0 && (
            <CustomButton
              title="Back"
              variant="secondary grey"
              onClick={prevStep}
            />
          )}
          <CustomButton title="Next" variant="primary" onClick={nextStep} disabled={loading}/>
        </div>
      </div>
    </div>
  );
}
