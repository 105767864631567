import { NavLink } from "react-router-dom";
import { AuthContext } from "components/lib";
import { useIntercom } from 'react-use-intercom';
import { MobileNavContext } from "app/mobilenav";
import { WhitelabelContext } from "app/whitelabel";
import { useContext, useState, useEffect } from "react";
import {
    UilTimes, 
    UilCalender, 
    UilInbox, 
    UilMessage, 
    UilDashboard, 
    UilAngleDown, 
    UilAngleUp, 
    UilListUl, 
    UilCheck, 
    UilAnalysis, 
    UilFile, 
    UilEnvelope, 
    UilCog,
    UilSchedule,
    UilQuestionCircle,
    UilBuilding,
    UilSignOutAlt
} from '@iconscout/react-unicons';
import './mobile_nav.scss';

export function MobileNav() {
    const [searchTerm, setSearchTerm] = useState('');
    const [workspaces, setWorkspaces] = useState([]);
    const [isMeetOpen, setIsMeetOpen] = useState(false);
    const [isOutreachOpen, setIsOutreachOpen] = useState(false);
    const [isWorkspaceOpen, setIsWorkspaceOpen] = useState(false);

    const { show } = useIntercom();

    const authContext = useContext(AuthContext);
    const {whitelabelDetails} = useContext(WhitelabelContext);
    const {isOpen, toggleMobileNav} = useContext(MobileNavContext);

    const outreachLinks = [
        { href: '/outreach/sequences', icon: <UilMessage />, label: 'Sequences' },
        { href: '/outreach/outbox', icon: <UilEnvelope />, label: 'Outbox' },
        { href: '/outreach/lists', icon: <UilListUl />, label: 'Lists' },
        { href: '/outreach/tasks', icon: <UilCheck />, label: 'Tasks' },
        { href: '/outreach/reports', icon: <UilAnalysis />, label: 'Reports' },
        { href: '/outreach/templates', icon: <UilFile />, label: 'Templates' },
        { href: '/outreach/email-senders', icon: <UilEnvelope />, label: 'Email Senders' },
        { href: '/outreach/settings', icon: <UilCog />, label: 'Settings' },
    ];

    const meetLinks = [
        { href: '/meet/my-calendar', icon: <UilSchedule />, label: 'My Calendar' },
        { href: '/meet/settings', icon: <UilCog />, label: 'Settings' },
    ];

    const accountName = authContext.user?.accounts?.find(
        (x) => x.id === authContext.user?.account_id
    )?.name;

    useEffect(() => {
        const accounts = authContext?.user?.accounts;
        const filteredWorkspaces = accounts?.filter(account => account.id !== authContext.user.account_id);
        setWorkspaces(filteredWorkspaces);
    }, []);

    return (
        <div id="mobile-nav" style={{ right: isOpen ? 0 : '-100%' }} className="drop-shadow-md ease-in-out duration-500 scroller">
            <div className="flex items-center justify-between w-full" >
                {whitelabelDetails?.headerLogo && 
                    <img
                        src={whitelabelDetails?.headerLogo}
                        alt={`${whitelabelDetails?.agencyName} Logo`}
                        width={140}
                        onClick={() => window.location.href = '/'}
                    />
                }
                <UilTimes onClick={() => toggleMobileNav()}  />
            </div>
            <hr className="mt-4" />
            <div className="flex flex-col justify-between" style={{ marginTop: '1rem', flexGrow: 1 }}>
                <div className="flex flex-col gap-4">
                    <NavLink className='flex items-center gap-2' to={'/dashboard'}><UilDashboard />Dashboard</NavLink>
                    <div>
                        <div className="flex gap-2" onClick={() => setIsOutreachOpen(prev => !prev)}>
                            <div className="flex items-center gap-2"><UilMessage />Outreach</div>
                            {isOutreachOpen ? <UilAngleUp /> : <UilAngleDown />}
                        </div>
                        {isOutreachOpen && <div className="flex flex-col gap-4" style={{ paddingTop: '1rem', paddingLeft: '1rem' }}>
                        {outreachLinks.map((link) => (
                            <NavLink key={link.href} className="flex items-center gap-2" to={link.href}>
                                {link.icon}
                                {link.label}
                            </NavLink>
                            ))}
                        </div>}
                    </div>
                    <NavLink className="flex items-center gap-2" to={'/inbox'}><UilInbox />Inbox</NavLink>
                    <div>
                        <div className="flex gap-2" onClick={() => setIsMeetOpen(prev => !prev)}>
                            <div className="flex items-center gap-2"><UilCalender />Meet</div>
                            {isMeetOpen ? <UilAngleUp /> : <UilAngleDown />}
                        </div>
                        {isMeetOpen && <div className="flex flex-col gap-4" style={{ paddingTop: '1rem', paddingLeft: '1rem' }}>
                        {meetLinks.map((link) => (
                            <NavLink key={link.href} className="flex items-center gap-2" to={link.href}>
                                {link.icon}
                                {link.label}
                            </NavLink>
                            ))}
                        </div>}
                    </div>
                </div>
                <div className="mt-4">
                    <hr />
                    <div className="flex flex-col gap-4">
                        <NavLink className="flex items-center gap-2 mt-4" to={'/account'}><UilCog />Settings</NavLink>
                        <div>
                            <div className="flex gap-2" onClick={() => setIsWorkspaceOpen(prev => !prev)}>
                                <UilBuilding style={{ flexShrink: 0 }} /><p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize' }}>{accountName}</p>
                                {workspaces?.length >= 1 ? isWorkspaceOpen ? <UilAngleUp style={{ flexShrink: 0 }} /> : <UilAngleDown style={{ flexShrink: 0 }} /> : null}
                            </div>
                            {isWorkspaceOpen && workspaces?.length >= 1 && <div className="flex flex-col rounded-md" style={{ border: '1px solid #d7d7d7', marginTop: 8, padding: '0.25rem' }}>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchTerm}
                                        style={{ width: '100%', paddingBlock: 4, paddingInline: 2 }}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    {searchTerm !== '' && <div className="mt-1">
                                        <hr />
                                    </div>}
                                </div>
                                {searchTerm !== '' && workspaces
                                    .filter(workspace => workspace.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                    .slice(0, 5)
                                    .map(workspace => (
                                        <div key={workspace.id} onClick={() => authContext.switchAccount(workspace.id)} className="flex items-center gap-2 mt-2 mb-2">
                                            <UilBuilding style={{ flexShrink: 0 }} /><p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{workspace.name}</p>
                                        </div>
                                ))}
                            </div>}
                        </div>
                        {authContext?.user?.permission !== 'client' && <button onClick={() => show()} className="flex items-center gap-2"><UilQuestionCircle />Help</button> }
                        <button onClick={() => authContext.signout()} className="flex items-center gap-2"><UilSignOutAlt />Sign Out</button>
                    </div>
                </div>
            </div>
        </div>
    )
}