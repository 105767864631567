import { CustomTable, useAPI, Loader } from 'components/lib';
import { UilDownloadAlt, UilBill } from '@iconscout/react-unicons';
import CustomButton from 'components/custom/customButton';
import { DateTime } from 'luxon';
import { Tooltip } from 'react-tooltip';

export function BillingInvoices(props) {
  const invoices = useAPI('/api/account/invoice');
  const columns = [
    {
      Header: 'Invoice Number',
      Footer: 'Invoice Number',
      accessor: 'number',
      disableSortBy: true,
    },
    {
      Header: 'Date',
      Footer: 'Date',
      accessor: 'date',
      disableSortBy: true,
      Cell: ({ value }) => {
        const date = DateTime.fromISO(value);

        const day = date.day;
        const suffix =
          day % 10 === 1 && day !== 11
            ? 'st'
            : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th';

        return date.toFormat(`d`) + suffix + date.toFormat(` MMM yyyy`);
      },
    },
    {
      Header: <span className="csCenter">Status</span>,
      accessor: 'status',
      disableSortBy: true,
      Cell: ({ value }) => {
        if (value === 'paid')
          return (
            <span
              className="csBadge success csCenter"
              style={{ textTransform: 'capitalize' }}
            >
              {value}
            </span>
          );
        return (
          <span
            className="csBadge danger csCenter"
            style={{ textTransform: 'capitalize' }}
          >
            {value}
          </span>
        );
      },
    },
    {
      Header: <span className="csCenter">Total</span>,
      accessor: 'total',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        let { original } = row;

        if (original.hosted_invoice_url !== '' && !invoices?.data?.invoices)
          return (
            <div className="csCenter" style={{ display: 'flex' }}>
              <span style={{ color: '#E53A40', marginTop: -1 }}>{value}</span>
              <CustomButton
                variant="primary"
                onClick={() =>
                  window.open(original.hosted_invoice_url, '_blank')
                }
                title={'Pay Now'}
                style={{
                  marginLeft: 10,
                  height: 28,
                  padding: 5,
                  marginTop: -2,
                  outline: 1,
                  border: '2px solid #97d8fb',
                  fontSize: 14,
                }}
              />
            </div>
          );
        return <span className="csCenter">{value}</span>;
      },
    },
    {
      Header: <span className="csCenter">Actions</span>,
      Footer: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({ row }) => {
        let { original } = row;

        if (original?.status === 'paid') 
          return (
            <div className="csCenter">
              <span className="csBadge primaryOutline" style={{ padding: 4 }}>
                <UilDownloadAlt
                  id="paid-bill"
                  size={20}
                  color="#03A6FF"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(original.invoice_pdf, '_blank');
                  }}
                />
                <Tooltip place='top' anchorSelect='#paid-bill'>Download Invoice</Tooltip>
              </span>
            </div>
          );
        return (
          <div className="csCenter">
            <span className="csBadge dangerOutline" style={{ padding: 4 }}>
              <UilBill
                id="unpaid-bill"
                size={20}
                color="#EF898C"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(original.invoice_pdf, '_blank');
                }}
              />
              <Tooltip place='top' anchorSelect='#unpaid-bill'>Download Invoice & Pay</Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  if (invoices.loading) return <Loader />;

  return (
    <div className={props.className}>
      {invoices?.data?.invoices ? (
        <CustomTable
          hasFooter={false}
          filter={''}
          columns={columns}
          data={invoices?.data?.invoices}
          pagination={false}
          onClick={(row) => {}}
        />
      ) : (
        <h1 className="font-bold">No invoice found.</h1>
      )}
    </div>
  );
}
