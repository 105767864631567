import "./radial-progress-bar.scss";

export const RadialProgressBar = ({ completed, total, size, id }) => {
    let percentage = Math.min(Math.ceil((completed / total) * 100), 100);
    const strokeDasharray = `${percentage} ${100 - percentage}`;
  
    return (
      <div id={id} className="radial-progress-bar" style={{ width: size, height: size, cursor: 'pointer' }}>
        <svg className="progress-circle" viewBox="0 0 36 36">
          <path
            className="progress-background"
            d="M18 2.0845
               a 15.9155 15.9155 0 0 1 0 31.831
               a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="progress-value"
            strokeDasharray={strokeDasharray}
            d="M18 2.0845
               a 15.9155 15.9155 0 0 1 0 31.831
               a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
        <div className="percentage" style={{ fontSize: size === 60 ? '12px' : '8px' }}>
          {percentage}%
        </div>
      </div>
    );
  };