import axios from 'axios';
import CustomButton from 'components/custom/customButton';
import {
  AuthContext,
  CustomTable,
  Loader,
  UpgradePlan,
  useAPI,
  useNavigate,
  useRouteCheck,
  WhitelabelContext,
} from 'components/lib';
import {
  UilPen,
  UilFire,
  UilGlobe,
  UilPlusCircle,
  UilEnvelopeTimes,
  UilEdit,
  UilTrash,
  UilClipboardBlank
} from '@iconscout/react-unicons';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import { Tooltip } from 'react-tooltip';
import DomainEditor from './DomainEditor';
import SignatureEditor from './SignatureEditor';
import WarmupLinksEditor from './WarmupLinksEditor';
import Toast from 'utils/toast';
import { truncateText } from 'utils/string-format';
import './settings.scss';

function EmailSignatures() {
  const [viewSignatureModal, setViewSignatureModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [signatures, setSignatures] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState(null);
  const authContext = useContext(AuthContext);
  const totalPages = 1;
  const columns = [
    {
      Header: 'Name',
      Footer: 'Name',
      accessor: 'name',
      Cell: ({ row, value }) => {
        const { original } = row;
        if (original.default === true) return <b>{value} (Default)</b>;
        else return truncateText(value, 20);
      },
    },
    {
      Header: <span className='csCenter'>Actions</span>,
      accessor: 'actions',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='csCenter'>
            <span className="csBadge primaryOutline ml-2 mr-2" style={{ padding: 4 }}>
              <UilClipboardBlank
                style={{ color: '#03A6FF', cursor: 'pointer' }}
                size={20}
                id={`copy-signature-${original.id}`}
                onClick={() => {
                  copyText(original.id);
                }}
              />
            </span>
            <span className="csBadge warningOutline mr-2" style={{ padding: 4 }}>
              <UilEdit
                color="#FBD355"
                size={16}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setViewSignatureModal(true);
                  setSelectedSignature(original);
                }}
              />
            </span>
            <span className="csBadge dangerOutline" style={{ padding: 4 }}>
              <UilTrash
                size={16}
                color="#EF898C"
                style={{ cursor: 'pointer' }}
                onClick={() => deleteSignature(original.id)}
              />
            </span>

            <Tooltip anchorSelect={`#copy-signature-${original.id}`} place="top">
              Copy Signature ID
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchSignatures();
  }, []);

  const copyText = (data) => {
    window.navigator.clipboard.writeText(data);
    Toast.fire({ icon: 'success', title: 'Id copied successfully' });
  };

  const deleteSignature = async (id) => {
    try {
      await axios.delete(`/api/signatures/${id}`);
      fetchSignatures();
    } catch (error) {}
  };

  const fetchSignatures = async () => {
    try {
      let result = await axios.get('/api/signatures');
      setSignatures(result.data.data);
    } catch (error) {}
  };

  return (
    <div>
      {viewSignatureModal && (
        <SignatureEditor
          selected={selectedSignature}
          onClose={() => {
            setViewSignatureModal(false);
            setSelectedSignature(null)
            fetchSignatures();
          }}
        />
      )}
      <div className='row-column-responsive' style={{ display: 'flex' }}>
        <h2 className="flex tableTitle mt-2">
          <UilPen size={25} style={{ marginRight: 10, marginTop: 3 }} /> Signatures
        </h2>
        <CustomButton
          title="Add"
          icon={<UilPlusCircle/>}
          className={'margin-left-responsive layout-reverse'}
          onClick={() => setViewSignatureModal(true)}
          style={{ display: 'flex' }}
          variant="secondary"
        />
      </div>
      <div style={{ marginTop: 15 }}>
        {signatures.length > 0 ? (
          <CustomTable
            hasFooter={false}
            columns={columns}
            pagination={false}
            data={signatures}
            count={10}
            perPage={perPage}
            onClick={(row) => {}}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            noBottomMargin={true}
          />
        ) : (
          <div>
            <p> No Email Signatures, Create one now!</p>
            <br />
            <p>
              Email Signatures can be appended in emails with variable {`{{email_signature_of_sender}}`}. 
              {authContext?.user?.permission !== 'client' && <a className='csCustomeLink justify-start mt-2 cursor-pointer' href='https://help.salesblink.io/en/articles/9080619-email-signature' target='_blank'>
                {/* <UilQuestionCircle size={18} style={{ marginRight: 3 }} /> */}
                Learn How
              </a>}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

function EmailWarmUpLinks() {
  const [viewLinksModal, setViewLinksModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [warmUpLinks, setWarmUpLinks] = useState([]);
  const [selectedLink, setSelectedLink] = useState(null);

  const authContext = useContext(AuthContext);

  const totalPages = 5;
  const columns = [
    {
      Header: 'Name',
      Footer: 'Name',
      accessor: 'name',
      Cell: ({ row, value }) => {
        const { original } = row;
        if (original.default === true) return <b>{value} (Default)</b>;
        else return truncateText(value, 20);
      },
    },
    {
      Header: <span className='csCenter'>Label</span>,
      accessor: 'label',
      Cell: ({ row, value }) => {
        const { original } = row;
        // if (original.default === true) return <b>{value} (Default)</b>;

        let text = value.length > 8 ? value.slice(0, 6) + "..." : value

        return <div className='text-center'>
        <span style={{ display: 'inline-flex', width: 85 }} className='csBadge info' id={`label-${original._id}`}>
          {text}
        </span>
        {value.length > 8 && <Tooltip anchorSelect={`#label-${original._id}`} place="top">
          {value}
        </Tooltip>}
      </div>
      },
    },
    {
      Header: 'Value',
      Footer: 'Value',
      accessor: 'value',
      Cell: ({ row, value }) => {
        const { original } = row;
        if (original.default === true) return <b>{value} (Default)</b>;
        else return truncateText(value, 20);
      },
    },
    {
      Header: <span className='csCenter'>Actions</span>,
      accessor: 'actions',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='csCenter'>
            <span className="csBadge warningOutline ml-2 mr-2" style={{ padding: 4 }}>
              <UilEdit
                color="#FBD355"
                size={16}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setViewLinksModal(true);
                  setSelectedLink(original);
                }}
              />
            </span>
            <span className="csBadge dangerOutline" style={{ padding: 4 }}>
              <UilTrash
                size={16}
                color="#EF898C"
                style={{ cursor: 'pointer' }}
                onClick={() => deleteWarmUpLinks(original.id)}
              />
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchWarmUpLinks();
  }, []);

  const deleteWarmUpLinks = async (id) => {
    try {
      await axios.delete(`/api/warmuplinks/${id}`);
      fetchWarmUpLinks();
    } catch (error) {}
  };

  const fetchWarmUpLinks = async () => {
    try {
      let result = await axios.get('/api/warmuplinks');
      setWarmUpLinks(result.data.data);
    } catch (error) {}
  };

  return (
    <div className='w-links'>
      {viewLinksModal && (
        <WarmupLinksEditor
          selected={selectedLink}
          onClose={() => {
            setViewLinksModal(false);
            setSelectedLink(null)
            fetchWarmUpLinks();
          }}
        />
      )}
      <div className='row-column-responsive' style={{ display: 'flex' }}>
        <h2 className="flex tableTitle mt-2">
          <UilFire size={25} style={{ marginRight: 10, marginTop: 3 }} /> Email WarmUp Links
        </h2>
        <CustomButton
          title="Add"
          icon={<UilPlusCircle/>}
          className={'margin-left-responsive layout-reverse'}
          onClick={() => {
            if (authContext.plan !== 'free') {
              setViewLinksModal(true);
            }
          }}
          style={{ display: 'flex' }}
          variant="secondary"
        />
      </div>
      <div style={{ marginTop: 15 }}>
        {warmUpLinks.length > 0 ? (
          <CustomTable
            hasFooter={false}
            columns={columns}
            data={warmUpLinks}
            pagination={false}
            count={10}
            perPage={perPage}
            onClick={(row) => {}}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            noBottomMargin={true}
          />
        ) : (
          <div>
            <p>No Warmup Links, Create One Now!</p>
            <br />
            <p>
              WarmUp your website links using Email WarmUp so that your cold emails with your website link don't land in spam. 
              {authContext?.user?.permission !== 'client' && <a className='csCustomeLink justify-start mt-2 cursor-pointer' href='https://help.salesblink.io/en/articles/9080622-email-warmup#h_dbb28c7d80' target='_blank'>
                {/* <UilQuestionCircle size={18} style={{ marginRight: 3 }} /> */}
                Learn How
              </a>}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

function CustomDomains() {
  const [viewDomainsModal, setViewDomainsModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [domains, setDomains] = useState([
    { domain: 'example.com', verified: false },
    { domain: 'example.com', verified: true },
    { domain: 'example.com', verified: false },
  ]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const authContext = useContext(AuthContext);
  const totalPages = 5;
  const columns = [
    {
      Header: 'Domain',
      Footer: 'Domain',
      accessor: 'domain',
      Cell: ({ value }) => {
        return truncateText(value, 20);
      },
    },
    {
      Header: <span className='csCenter'>Verified</span>,
      accessor: 'verified',
      Cell: ({ value }) => {
        if (value) return <span className='csBadge success csCenter'>
          Verified
        </span>
        return <span className='csBadge danger csCenter'>
          Unverified
        </span> 
      },
    },
    {
      Header: <span className='csCenter'>Actions</span>,
      accessor: 'actions',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='csCenter'>
            <span className="csBadge warningOutline ml-2 mr-2" style={{ padding: 4 }}>
              <UilEdit
                color="#FBD355"
                size={16}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setViewDomainsModal(true);
                  setSelectedDomain(original);
                }}
              />
            </span>
            <span className="csBadge dangerOutline" style={{ padding: 4 }}>
              <UilTrash
                size={16}
                color="#EF898C"
                style={{ cursor: 'pointer' }}
                onClick={() => deleteDomains(original._id)}
              />
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchDomains();
  }, []);

  const deleteDomains = async (id) => {
    try {
      await axios.delete(`/api/domains/${id}`);
      fetchDomains();
    } catch (error) {}
  };

  const fetchDomains = async () => {
    try {
      let result = await axios.get('/api/domains');
      setDomains(result.data.data);
    } catch (error) {}
  };

  return (
    <div className='w-domains'>
      {viewDomainsModal && (
        <DomainEditor
          selected={selectedDomain}
          onClose={() => {
            setViewDomainsModal(false);
            setSelectedDomain(null);
            fetchDomains();
          }}
        />
      )}
      <div className='row-column-responsive' style={{ display: 'flex' }}>
        <h2 className="flex tableTitle mt-2">
          <UilGlobe size={25} style={{ marginRight: 10, marginTop: 3 }} /> Custom Domains
        </h2>
        <CustomButton
          title="Add"
          icon={<UilPlusCircle/>}
          className={'margin-left-responsive layout-reverse'}
          onClick={() => setViewDomainsModal(true)}
          style={{ display: 'flex' }}
          variant="secondary"
        />
      </div>
      <div style={{ marginTop: 15 }}>
        {domains.length > 0 ? (
          <CustomTable
            hasFooter={false}
            columns={columns}
            data={domains}
            pagination={false}
            count={10}
            perPage={perPage}
            onClick={(row) => {}}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            noBottomMargin={true}
          />
        ) : (
          <div>
            {' '}
            <p>No Domains, Add a custom domain now!</p> <br />{' '}
            <p>
              Custom Domains are used to track opens and link clicks. It is recommended to add one to improve email deliverability.
              {authContext?.user?.permission !== 'client' && <a className='csCustomeLink justify-start mt-2 cursor-pointer' href='https://help.salesblink.io/en/articles/9080625-custom-domains-for-email-tracking' target='_blank'>
                {/* <UilQuestionCircle size={18} style={{ marginRight: 3 }} /> */}
                Learn How
              </a>}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

function UnsubscribedEmail() {
  const [unsubscribed, setUnsubscribed] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchLogs();
  }, []);

  async function fetchLogs() {
    try {
      const res = await axios({
        url: `/api/logs?limit=${500}&page=${0}&type=unsubscribed&message=Unsubscribed&date=${
          DateTime.now().plus({ days: -7 }).ts
        }-${DateTime.now().ts}`,
        method: 'get',
      });
      setUnsubscribed(res?.data?.count);
    } catch (error) {
      console.log('ERROR: ', error);
    }
  }

  return (
    <div style={{ marginTop: 32 }}>
      <div className='row-column-responsive' style={{ display: 'flex' }}>
        <h2 className="flex tableTitle mt-2">
          <UilEnvelopeTimes size={25} style={{ marginRight: 10, marginTop: 3 }} /> Unsubscribed Email
        </h2>
        <CustomButton
          title="Manage Unsubscribed"
          icon={<UilPlusCircle/>}
          className={'margin-left-responsive layout-reverse'}
          onClick={() => navigate('/manage-unsubscribed')}
          style={{ display: 'flex' }}
          variant="secondary"
        />
      </div>
      <div style={{ marginTop: 15, border: '1px solid #D7D7D7', borderRadius: 5, boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.05)', fontSize: 16, fontWeight: 500, padding: 20 }}>
        <div>
          <p><span style={{ color: '#3AC569' }}>{unsubscribed}</span> unsubscribed in the last 7 days</p>
          <br />
          <p>
            Recipients who unsubscribe from your email sequences can be found
            here, you can also add them manually to avoid sending emails to
            specific email addresses or domains.
          </p>
        </div>
      </div>
    </div>
  );
}

export default function Settings() {
  const auth = useAPI('/api/auth');
  const allowRoute = useRouteCheck(
    window.location.pathname,
    auth.data?.plan,
    auth.data?.subscription,
    auth.data?.expired
  );
  const { whitelabelDetails } = useContext(WhitelabelContext);

  if (!allowRoute) {
    if (auth.loading) return <Loader />;
    else return <UpgradePlan />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Outreach Settings | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      <Row className='mt-4'>
        <Col xs={12} lg={6} xl={4}>
          <EmailSignatures />
        </Col>
        <Col xs={12} lg={6} xl={4}>
          <EmailWarmUpLinks />
        </Col>
        <Col xs={12} lg={6} xl={4}>
          <CustomDomains />
        </Col>
      </Row>
      <Row>
        <Col>
          <UnsubscribedEmail />
        </Col>
      </Row>
    </div>
  );
}
