import {
  UilEnvelopeCheck,
  UilExclamationOctagon,
  UilDownloadAlt,
  UilEnvelopeOpen,
  UilEnvelopeTimes,
  UilClock,
  UilCheckCircle,
  UilEnvelopeReceive,
  UilLink,
  UilExternalLinkAlt,
  UilEnvelopeRedo,
  UilAngleUp,
  UilAngleDown,
  UilFilter,
  UilTimes
} from '@iconscout/react-unicons';
import Axios from 'axios';
import {
  Animate,
  AuthContext,
  CustomTable,
  useAPI,
  PageTitle,
  Loader,
} from 'components/lib';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { Col, Row } from 'react-grid-system';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Tooltip } from 'react-tooltip';
import timezones from '../../assets/data/timezone';
import CustomButton from 'components/custom/customButton';
import { useLocation } from 'react-router-dom';
import './logsreports.scss';

export const EVENT_TYPES = {
  'Email Sent / Scheduled / Rescheduled': 'outreach',
  'Email Opened': 'open',
  'Link In Email Clicked': 'click',
  'Email Received a Reply': 'reply',
  'Email Bounced': 'bounce',
  'Task Completed / Rescheduled': 'task',
  Unsubscribed: 'unsubscribed',
  Error: 'error',
};

export default function LogsReports() {
  return (
    <div>
      <Row>
        <Col>
          <SequenceLog />
        </Col>
      </Row>
    </div>
  );
}

function ErrorView({ body }) {
  const [open, setOpen] = useState(false);

  const viewData = () => {
    if(!body) return null;
    if(body.message) {
      if(typeof body.message === "string") {
        if(body.log) {
          if(typeof body.log === "string") return `${body.message} - ${body.log}`
          return `${body.message} - ${JSON.stringify(body.log)}`
        } else return body.message
      }
      else return JSON.stringify(body.message)
    }
    return JSON.stringify(body)
  }

  return (
    <>
      <span
        className="csBadge danger csCenter"
        onMouseOver={(e) => setOpen(true)}
        onMouseOut={(e) => setOpen(false)}
        // style={{ width: 'auto', display: 'inline-block' }}
      >
        {' '}
        <UilExclamationOctagon
          size={16}
          style={{ marginTop: 3, marginRight: 5 }}
        />{' '}
        Error{' '}
      </span>
      {open && body && (
        <div className={`dropdown-content`}>
          <div>{viewData()}</div>
        </div>
      )}
    </>
  );
}

function SequenceLog() {
  const query = new URLSearchParams(window.location.search);
  const { state } = useLocation();
  const initialDate = query.get('date');
  const startDate = initialDate ? new Date(initialDate) : null;

  const [sequences, setSequences] = useState([]);
  const [sequencesData, setSequencesData] = useState([]);
  const [sequence, setSequence] = useState(null || state?.sequence);

  const [nodes, setNodes] = useState([]);
  const [node, setNode] = useState('');

  const [email, setEmail] = useState('');
  const [showDatePicker, setShowDatePicker] = useState('');
  const [StartDate, setStartDate] = useState(startDate || null);
  const [EndDate, setEndDate] = useState(startDate || null);
  const [start, setStart] = useState(
    startDate ? DateTime.fromJSDate(startDate).startOf('day').ts : null
  );
  const [end, setEnd] = useState(
    startDate ? DateTime.fromJSDate(startDate).startOf('day').ts : null
  );
  const [RangeView, setRangeView] = useState(
    startDate
      ? `From ${DateTime.fromJSDate(startDate).toFormat(
          'MM-dd-yyyy'
        )} To ${DateTime.fromJSDate(startDate).toFormat('MM-dd-yyyy')}`
      : null
  );

  const [logs, setLogs] = useState([]);
  const [logsCount, setLogsCount] = useState(0);

  let search = '';
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const usersApi = useAPI('/api/account/owner-list');

  const [ownedBy, setOwnedBy] = useState('');
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');
  const [isSorted, setIsSorted] = useState('');
  const [isSortedDesc, setIsSortedDesc] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState(
    query.get('event') ? [query.get('event')] : []
  );

  const [senders, setSenders] = useState([]);
  const [selectedSender, setSelectedSender] = useState('' || state?.sender);
  const [filterVisible, setFilterVisible] = useState(false);

  const authContext = useContext(AuthContext);
  
  // useEffect(() => {
  //   if (initialDate) {
  //     const startD = DateTime.fromJSDate(initialDate);
  //     const endD = DateTime.fromJSDate(initialDate);

  //     setStartDate(initialDate);
  //     setEndDate(initialDate);

  //     setStart(startD.ts);
  //     setEnd(endD.ts);

  //     setRangeView(
  //       `From ${startD.toFormat('MM-dd-yyyy')} To ${endD.toFormat(
  //         'MM-dd-yyyy'
  //       )}`
  //     );
  //   }
  // }, [initialDate]);

  useEffect(() => {
    if (query.get('team') && authContext.user.permission === 'owner')
      setOwnedBy(query.get('team') || authContext.user.id);
    else setOwnedBy(authContext.user.id);
  }, []);

  useEffect(() => {
    if(!ownedBy){
      fetchSenders()
    }
    if (ownedBy) {
      fetchSequences('');
      fetchSenders();
    }
  }, [ownedBy]);

  useEffect(() => {
    if (query.get('sender')) {
      setSelectedSender(senders)
    }
  }, [senders]);

  useEffect(() => {
    setTimeout(() => {
      if (query.get('sequence') && query.get('sequence').length>0 && typeof sequence !== 'undefined') {
        if(query.get('team') && query.get('team').length>0 && typeof ownedBy !== 'undefined' && typeof selectedEvents !== 'undefined'){
          fetchLogs(); 
        }else if(query.get('team') == null && query.get('event') && query.get('event').length>0 && typeof selectedEvents !== 'undefined'){
          fetchLogs()
        }else if(query.get('event') == null && query.get('team') == null){
          fetchLogs()
        }
      }else if(query.get('sequence') == null){
        fetchLogs()
      }
    }, 300);
  }, [
    sequence,
    node,
    page,
    perPage,
    start,
    end,
    initialDate,
    // email,
    isSorted,
    isSortedDesc,
    ownedBy,
    selectedEvents,
    selectedSender,
  ]);

  useEffect(() => {
    if (query.get('sequence') && query.get('sequence').length>0) {
      fetchquerysequence(query.get('sequence'))
    }
  },[])

  useEffect(() => {
    if (logsCount > 0) setTotalPages(Math.ceil(logsCount / perPage));
  }, [logsCount, perPage]);

  useEffect(() => {
    if (usersApi?.data?.users)
      setUsers(
        usersApi.data.users?.map((userObj) => ({
          label: `${userObj.name} (${userObj.email})`,
          value: userObj?.id,
        }))
      );
    if (usersApi?.data?.user) setUser(usersApi.data.user);
  }, [usersApi]);

  useEffect(() => {
    getRequiredSequence();
  }, [logs]);

  const fetchquerysequence = async (sequenceid) =>{
    try {
      let result = await Axios.get(`/api/sequences/${sequenceid}`);
      let _sequence = result?.data?.data;
      setSequence(_sequence);
    } catch (error) {
      console.log('ERROR: ', error);
    }
  }

  const getRequiredSequence = async () => {
    try {
      let seqArr = [];
      let oldSeqInfo = sequencesData;
      let newSeqInfo = [];
      for (let log of logs) {
        let seqExist = oldSeqInfo.find((_seq) => _seq.id === log?.sequence);
        if (!seqExist) {
          seqArr.push(log?.sequence);
          newSeqInfo.push({ id: log?.sequence });
        } else if (seqExist && seqExist.name) {
          newSeqInfo.push({ id: log?.sequence, name: seqExist?.name });
        } else seqArr.push(log?.sequence);
      }

      if (seqArr.length > 0) {
        let result = await Axios.get(
          `/api/multi-sequence?ids=${seqArr.join(',')}&userID=${ownedBy}`
        );
        const data = result?.data?.data;

        let newSeqInfo1 = [];
        for (let seqInfo of newSeqInfo) {
          let dataExist = data.find((_seq) => _seq.id === seqInfo.id);
          if (dataExist)
            newSeqInfo1.push({ id: dataExist.id, name: dataExist.name });
          else newSeqInfo1.push(seqInfo);
        }
        setSequencesData(newSeqInfo1);
      } else setSequencesData(newSeqInfo);
    } catch (e) {}
  };

  const columns = [
    {
      Header: (
        <div
          style={{ cursor: 'pointer', marginLeft: 5, display: 'flex' }}
          onClick={() => {
            if (isSorted === '' || isSorted !== 'Date') setIsSorted('Date');
            else if (isSorted === 'Date') setIsSortedDesc(!isSortedDesc);
          }}
        >
          Date
          <span>{isSortedDesc ? <UilAngleUp /> : <UilAngleDown />}</span>
        </div>
      ),
      Footer: 'Date',
      accessor: 'time',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;
        let seqInfo = sequences?.find((_seq) => _seq.id === original?.sequence);
        let zoneInfo = timezones.find((t) => t.id === seqInfo?.timezone);
        if (!zoneInfo && seqInfo?.timezone.includes('/'))
          zoneInfo = { utc: [seqInfo?.timezone] };
        let scheduledDate = DateTime.fromMillis(value);
        if (zoneInfo) scheduledDate = scheduledDate.setZone(zoneInfo.utc[0]);

        const day = scheduledDate.day;
        const suffix =
          day % 10 === 1 && day !== 11
            ? 'st'
            : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th';

        return (
          <div style={{ marginLeft: 5 }} data-tooltip-content={seqInfo?.timezone} data-tooltip-timezone="true">
            {scheduledDate.toFormat(`d`) +
              suffix +
              scheduledDate.toFormat(` MMM yyyy, hh:mm a`)}
          </div>
        );
      }, // formatting date
    },
    {
      Header: <span className="csCenter">Event Type</span>,
      accessor: 'message',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;

        if (value === 'Error')
          return (
            <ErrorView
              body={original.body ? JSON.parse(original.body) : null}
            />
          );
        if (value === 'Sent')
          return (
            <span className="csBadge success csCenter">
              {' '}
              <UilEnvelopeCheck
                size={16}
                style={{ marginTop: 3, marginRight: 5 }}
              />{' '}
              Email Sent{' '}
            </span>
          );
        if (value === 'Scheduled')
          return (
            <span className="csBadge warning csCenter">
              {' '}
              <UilClock
                size={16}
                style={{ marginTop: 3, marginRight: 5 }}
              />{' '}
              {original.type === 'task' ? 'Task' : 'Email'} Scheduled{' '}
            </span>
          );
        if (value === 'Email Opened')
          return (
            <span className="csBadge success csCenter">
              {' '}
              <UilEnvelopeOpen
                size={16}
                style={{ marginTop: 3, marginRight: 5 }}
              />{' '}
              Email Opened{' '}
            </span>
          );
        if (value === 'Link Clicked')
          return (
            <div>
              <span
                className={`csBadge success csCenter ${
                  original.link ? 'cursor-pointer' : ''
                }`}
                id={`link-${original.id}`}
              >
                {' '}
                <UilLink
                  size={16}
                  style={{ marginTop: 3, marginRight: 5 }}
                />{' '}
                Link in Email Clicked{' '}
              </span>
              <Tooltip anchorSelect={`#link-${original.id}`} place="top">
                {original.link}
              </Tooltip>
            </div>
          );
        if (value === 'Email Replied')
          return (
            <span className="csBadge success csCenter">
              {' '}
              <UilEnvelopeReceive
                size={16}
                style={{ marginTop: 3, marginRight: 5 }}
              />{' '}
              Email Received a Reply{' '}
            </span>
          );
        if (value === 'Email Bounced')
          return (
            <span className="csBadge danger csCenter">
              {' '}
              <UilEnvelopeTimes
                size={16}
                style={{ marginTop: 3, marginRight: 5 }}
              />{' '}
              Email Bounced{' '}
            </span>
          );
        if (value === 'Completed')
          return (
            <span className="csBadge success csCenter">
              {' '}
              <UilCheckCircle
                size={16}
                style={{ marginTop: 3, marginRight: 5 }}
              />{' '}
              Task Completed{' '}
            </span>
          );
        if (value === 'Rescheduled')
          return (
            <span className="csBadge warning csCenter">
              {' '}
              <UilEnvelopeRedo
                size={16}
                style={{ marginTop: 3, marginRight: 5 }}
              />{' '}
              Rescheduled{' '}
            </span>
          );
        if (value.includes('Unsubscribed'))
          return (
            <span className="csBadge danger csCenter">
              {' '}
              <UilEnvelopeTimes
                size={16}
                style={{ marginTop: 3, marginRight: 5 }}
              />{' '}
              Unsubscribed{' '}
            </span>
          );

        let seqInfo = sequences?.find((_seq) => _seq.id === original?.sequence);
        let zoneInfo = timezones.find((t) => t.id === seqInfo?.timezone);
        if (!zoneInfo && seqInfo?.timezone.includes('/'))
          zoneInfo = { utc: [seqInfo?.timezone] };
        let scheduledDate = DateTime.fromMillis(original.time);
        if (zoneInfo) scheduledDate = scheduledDate.setZone(zoneInfo.utc[0]);
        return `${value} at ${scheduledDate.toFormat('hh:mm a')}`;
      }, // formatting date
    },
    {
      Header: 'Email',
      accessor: 'email',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;
        if (original.contact_id)
          return (
            <a
              href={`/contact/${original.contact_id?._id}`}
              className="csCustomeLink"
              style={{ color: '#3AC569' }}
              target="_blank"
            >
              {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
              <span className="csLinkEmpty"></span>
            </a>
          );

        return value;
      },
    },
    {
      Header: 'Email Sender',
      accessor: 'senderEmail',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return value || 'Sender was Removed';
      },
    },
    {
      Header: <span className="csCenter">Type</span>,
      disableSortBy: true,
      accessor: 'node',
      Cell: ({ row, value }) => {
        const { cells } = row;
        let originalValue = cells[0].row.original;
        let seqInfo = sequences?.find(
          (_seq) => _seq.id === originalValue?.sequence
        );
        let nodeDetails = seqInfo?.flowchart?.nodes?.find(
          (n) => n.id === value
        );

        return (
          <span
            className="csBadge info csCenter"
            data-tooltip-show="true"
            data-tooltip-content={
              originalValue.email_template?.name ||
              originalValue.task_template?.name
            }
          >
            {nodeDetails?.data?.tasktype?.label ||
              nodeDetails?.data?.send_as?.label ||
              'Email'}
          </span>
        );
      },
    },
    {
      Header: 'Sequence',
      Footer: 'Sequence',
      disableSortBy: true,
      accessor: 'sequence',
      Cell: ({ row, value }) => {
        let seqInfo = sequencesData?.find((_seq) => _seq.id === value);
        return seqInfo?.name || '';
      },
    },
  ];

  async function fetchLogs() {
    setIsLoading(true);
    try {
      let dateStr = '';
      if (start && end) dateStr = `&date=${start}-${end}`;
      const events = selectedEvents.length
        ? selectedEvents.join(',')
        : Object.values(EVENT_TYPES);

      const res = await Axios({
        url: `/api/logs?limit=${perPage}&page=${page}&type=${events}&sequence=${
          sequence?.id || ''
        }&node=${node}&email=${email}${dateStr}&ownedby=${ownedBy}&sortBy=${isSorted}&sender=${
          selectedSender?.id || ''
        }&sortType=${isSortedDesc ? 'DESC' : 'ASC'}`,
        method: 'get',
      });
      setLogs(res?.data?.data);
      setLogsCount(res?.data?.count);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('ERROR: ', error);
    }
  }

  async function fetchSequences(inputValue) {
    try {
      let result = await Axios.get(
        `/api/sequences?limit=10&search=${
          inputValue
        }&userID=${ownedBy}`
      );
      const data = result?.data?.data;

      // Transform the fetched data into options array
      const options = data.map((item) => ({
        id: item.id,
        name: item.name,
        ...item,
      }));
      setSequences(options);

      return options;
    } catch (err) {
      console.log('Error in getting sequences ', err);
      return [];
    }
  }

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    
    setStart(DateTime.fromJSDate(ranges.selection.startDate).ts);
    setEnd(DateTime.fromJSDate(ranges.selection.endDate).ts);

    const startD = DateTime.fromJSDate(ranges.selection.startDate);
    const endD = DateTime.fromJSDate(ranges.selection.endDate);

    setRangeView(
      `From ${startD.toFormat('MM-dd-yyyy')} To ${endD.toFormat('MM-dd-yyyy')}`
    );
  };

  function handleEventTypeSelect(inputs) {
    setSelectedEvents(inputs.map((input) => input.value));
    setPage(0);
  }

  function downloadLogs() {
    let dateStr = '';
    if (start && end) dateStr = `&date=${start}-${end}`;

    const events = selectedEvents.length
      ? selectedEvents.join(',')
      : Object.values(EVENT_TYPES);

    window.open(
      `/api/logs-download?type=${events}&sequence=${
        sequence?.id || ''
      }&node=${node}&email=${email}${dateStr}&ownedby=${ownedBy}&sender=${selectedSender?.id || ''}&sortBy=${isSorted}&sortType=${
        isSortedDesc ? 'DESC' : 'ASC'
      }&u=${user}`,
      '_blank'
    );
  }

  async function fetchSenders(searchQuery) {
    try {
      const senderQuery = query.get("sender");
      let url = '/api/senders';
      if (searchQuery?.length > 0 || senderQuery?.length>0) {
        url += '?search=' + (searchQuery || senderQuery);
        if(ownedBy && ownedBy.length>0) url += '&ownedby=' + ownedBy
      }else{
        if(ownedBy && ownedBy.length>0) url += '?ownedby=' + ownedBy
      }
      const result = await Axios.get(url);
      if (result?.data?.success) {
        let senderList = result?.data?.data.map((s) => {
          s['email'] =
            s?.alias || s?.microsoft_email || s?.google_email || s?.smtpImapEmail;
          return s;
        })
        setSenders(senderList);
        return senderList
      }
      
      // setSenderCount(result.data.count);
      // setTotalPages(Math.ceil(result.data.count / perPage));
    } catch (err) {
      console.log('Error in getting sequences ', err.response);
    }
  }

  return (
    <>
      <Row className="mt-4">
        <Col id='logs-report-filter' xs={12} lg={3}>
          <div
            style={{
              height: '80vh',
              borderRight: '1px solid #D7D7D7',
              paddingRight: 30,
            }}
          >
            {/* date range */}
            <div>
              <input
                type="text"
                placeholder={'Date Range'}
                className="rounded"
                style={{
                  width: '100%',
                  border: '1px solid #e5e5e5',
                  padding: '7px 12px',
                  borderRadius: 3,
                  marginBottom: 8,
                }}
                value={RangeView}
                onClick={(e) => setShowDatePicker(!showDatePicker)}
                onChange={(e) => {setPage(0)}}
              />
              {showDatePicker && (
                <Animate type="slidedown" timeout={50}>
                  <div
                    className="dropdownMenu"
                    style={{
                      zIndex: 9999,
                      left: 16,
                      right: 'initial',
                      marginTop: 16,
                    }}
                  >
                    <div
                      style={{
                        position: 'fixed',
                        inset: 0,
                        zIndex: -1,
                      }}
                      onClick={() => setShowDatePicker(false)}
                    ></div>
                    <div className="dropdownMenuBody">
                      <DateRangePicker
                        ranges={[
                          {
                            startDate: StartDate,
                            endDate: EndDate,
                            key: 'selection',
                          },
                        ]}
                        onChange={handleSelect}
                        inputRanges={[]}
                      />
                    </div>
                  </div>
                </Animate>
              )}
            </div>
            {/* owned by */}
            {(authContext.user.permission === 'owner' || authContext.user.permission === 'admin') &&
            (
              <Row className="mb-2">
                <Col md={12}>
                  <Select
                    styles={{
                      control: () => ({
                        padding: 1,
                        width: '100%',
                        borderRadius: 5,
                        display: 'flex',
                        border: '1px solid #e5e5e5',
                      }),
                    }}
                    placeholder="Owned By"
                    value={
                      users?.find((userObj) => userObj.value === ownedBy) ||
                      null
                    }
                    isClearable
                    options={users}
                    onChange={(selected) => {
                      if (selected){ 
                        setOwnedBy(selected.value);
                      }else{
                        setOwnedBy('');
                        setSelectedSender(null);
                      }
                    }}
                  />
                </Col>
              </Row>
            )}

            {/* select sequence */}
            <AsyncSelect
              styles={{
                control: () => ({
                  padding: 1,
                  width: '100%',
                  borderRadius: 5,
                  display: 'flex',
                  border: '1px solid #d7d7d7',
                  marginBottom: 10,
                }),
              }}
              placeholder="Select Sequence"
              value={sequence}
              defaultOptions={sequences}
              isClearable
              loadOptions={fetchSequences} // Function to fetch sequence options asynchronously
              getOptionValue={(option) => option.id} // Define how to get the value of each option
              getOptionLabel={(option) => option.name} // Define how to get the label of each option
              onChange={(selected) => {
                if (selected) {
                  setPage(0);
                  setSequence(selected);
                  if (selected.flowchart) {
                    let newNodes = selected.flowchart.nodes?.filter(
                      (n) => n.group === 'outreach'
                    );
                    setNodes(newNodes);
                  }
                } else {
                  setSequence(null);
                  setNode('');
                }
              }}
              noOptionsMessage={() => 'No Results, Type to Search'}
            />

            {/* select block */}
            <Select
              styles={{
                control: () => ({
                  padding: 1,
                  width: '100%',
                  borderRadius: 5,
                  display: 'flex',
                  border: '1px solid #e5e5e5',
                  marginBottom: 10,
                }),
              }}
              placeholder="Select Block"
              isClearable
              defaultValue={node}
              options={nodes?.map((nodeObj) => ({
                label:
                  nodeObj?.data?.tasktype?.label ||
                  nodeObj?.data?.email_templates?.label ||
                  nodeObj?.data?.send_as?.label,
                value: nodeObj?.id,
              }))}
              onChange={(selected) => {
                if (selected) {
                  setPage(0);
                  setNode(selected.value);
                } else setNode('');
              }}
            />

            {/* select email sender block */}
            <AsyncSelect
              styles={{
                control: () => ({
                  padding: 1,
                  width: '100%',
                  borderRadius: 5,
                  display: 'flex',
                  border: '1px solid #d7d7d7',
                  marginBottom: 10,
                }),
              }}
              placeholder="Email Sender"
              value={selectedSender}
              defaultOptions={senders}
              isClearable
              loadOptions={fetchSenders} // Function to fetch sequence options asynchronously
              getOptionValue={(option) => option.id} // Define how to get the value of each option
              getOptionLabel={(option) =>option.email|| option.microsoft_email || option.google_email||option.smtpImapEmail} // Define how to get the label of each option
              onChange={(selected) => {
                if (selected) {
                  setPage(0);
                  setSelectedSender(selected);
                  if (selected.flowchart) {
                    let newNodes = selected.flowchart.nodes?.filter(
                      (n) => n.group === 'outreach'
                    );
                    setNodes(newNodes);
                  }
                } else {
                  setSelectedSender(null);
                  fetchSenders()
                  setNode('');
                }
              }}
              noOptionsMessage={() => 'No Results, Type to Search'}
            />

            <div className="searchPeople rounded mb-2" style={{ height: 38 }}>
              <input
                type="text"
                placeholder={'Email Address of Lead'}
                value={email}
                onChange={(e) => {setPage(0); setEmail(e.target.value.trim());}}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') fetchLogs();
                }}
              />
            </div>

            {/* select event type */}
            <Select
              styles={{
                control: () => ({
                  padding: 1,
                  width: '100%',
                  borderRadius: 5,
                  display: 'flex',
                  border: '1px solid #e5e5e5',
                  marginBottom: 10,
                }),
              }}
              placeholder="Select Event Type"
              value={(function () {
                const events = [];

                for (const event of selectedEvents) {
                  const eventFound = Object.entries(EVENT_TYPES).find(
                    ([key, value]) => value === event
                  );
                  if (eventFound)
                    events.push({ label: eventFound[0], value: eventFound[1] });
                }

                return events;
              })()}
              isClearable
              isMulti
              options={Object.entries(EVENT_TYPES).map(([label, value]) => ({
                label,
                value,
              }))}
              onChange={handleEventTypeSelect}
            />

            <CustomButton
              title="Get Logs"
              variant="primary"
              style={{ width: '100%' }}
              onClick={() => fetchLogs()}
            />
          </div>
        </Col>
        <Col xs={12} lg={9}>
          <Row className="mb-3">
            <Col xs={12} lg={9}>
              <PageTitle
                title="Sequence Logs"
                description="Logs for all activities that happen in any sequence."
              />
            </Col>
            <Col xs={12} lg={3} id='logs-report-filter-download-buttons'>
              <CustomButton
                id='toggle-filter-button'
                onClick={() => setFilterVisible(prev => !prev)}
                title={filterVisible ? 'Hide Filters' : 'View Filters'}
                variant="secondary"
                icon={filterVisible ? <UilTimes /> : <UilFilter />}
              />
              {logs.length > 0 && (
                <button
                  id="download-reports"
                  className="flex csBadge primaryOutline"
                  style={{ padding: 6 }}
                  type="button"
                  onClick={downloadLogs}
                >
                  <UilDownloadAlt size={26} color="#03A6FF" />
                </button>
              )}
              <Tooltip anchorSelect="#download-reports" place="top">
                Download Report
              </Tooltip>
            </Col>
            <div id='mobile-logs-report-filter' style={{ marginLeft: 16 }}>
              <div
                style={{
                  marginBlock: 8,
                  display: filterVisible ? 'block' : 'none',
                }}
              >
                {/* date range */}
                <div>
                  <input
                    type="text"
                    placeholder={'Date Range'}
                    className="rounded"
                    style={{
                      width: '100%',
                      border: '1px solid #e5e5e5',
                      padding: '7px 12px',
                      borderRadius: 3,
                      marginBottom: 8,
                    }}
                    value={RangeView}
                    onClick={(e) => setShowDatePicker(!showDatePicker)}
                    onChange={(e) => {setPage(0)}}
                  />
                  {showDatePicker && (
                    <Animate type="slidedown" timeout={50}>
                      <div
                        className="dropdownMenu"
                        style={{
                          zIndex: 9999,
                          left: 16,
                          right: 'initial',
                          marginTop: 16,
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            inset: 0,
                            zIndex: -1,
                          }}
                          onClick={() => setShowDatePicker(false)}
                        ></div>
                        <div className="dropdownMenuBody">
                          <DateRangePicker
                            ranges={[
                              {
                                startDate: StartDate,
                                endDate: EndDate,
                                key: 'selection',
                              },
                            ]}
                            onChange={handleSelect}
                            inputRanges={[]}
                          />
                        </div>
                      </div>
                    </Animate>
                  )}
                </div>
                {/* owned by */}
                {(authContext.user.permission === 'owner' || authContext.user.permission === 'admin') &&
                (
                  <Row className="mb-2">
                    <Col md={12}>
                      <Select
                        styles={{
                          control: () => ({
                            padding: 1,
                            width: '100%',
                            borderRadius: 5,
                            display: 'flex',
                            border: '1px solid #e5e5e5',
                          }),
                        }}
                        placeholder="Owned By"
                        value={
                          users?.find((userObj) => userObj.value === ownedBy) ||
                          null
                        }
                        isClearable
                        options={users}
                        onChange={(selected) => {
                          if (selected){ 
                            setOwnedBy(selected.value);
                          }else{
                            setOwnedBy('');
                            setSelectedSender(null);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                )}

                {/* select sequence */}
                <AsyncSelect
                  styles={{
                    control: () => ({
                      padding: 1,
                      width: '100%',
                      borderRadius: 5,
                      display: 'flex',
                      border: '1px solid #d7d7d7',
                      marginBottom: 10,
                    }),
                  }}
                  placeholder="Select Sequence"
                  value={sequence}
                  defaultOptions={sequences}
                  isClearable
                  loadOptions={fetchSequences} // Function to fetch sequence options asynchronously
                  getOptionValue={(option) => option.id} // Define how to get the value of each option
                  getOptionLabel={(option) => option.name} // Define how to get the label of each option
                  onChange={(selected) => {
                    if (selected) {
                      setPage(0);
                      setSequence(selected);
                      if (selected.flowchart) {
                        let newNodes = selected.flowchart.nodes?.filter(
                          (n) => n.group === 'outreach'
                        );
                        setNodes(newNodes);
                      }
                    } else {
                      setSequence(null);
                      setNode('');
                    }
                  }}
                  noOptionsMessage={() => 'No Results, Type to Search'}
                />

                {/* select block */}
                <Select
                  styles={{
                    control: () => ({
                      padding: 1,
                      width: '100%',
                      borderRadius: 5,
                      display: 'flex',
                      border: '1px solid #e5e5e5',
                      marginBottom: 10,
                    }),
                  }}
                  placeholder="Select Block"
                  isClearable
                  defaultValue={node}
                  options={nodes?.map((nodeObj) => ({
                    label:
                      nodeObj?.data?.tasktype?.label ||
                      nodeObj?.data?.email_templates?.label ||
                      nodeObj?.data?.send_as?.label,
                    value: nodeObj?.id,
                  }))}
                  onChange={(selected) => {
                    if (selected) {
                      setPage(0);
                      setNode(selected.value);
                    } else setNode('');
                  }}
                />

                {/* select email sender block */}
                <AsyncSelect
                  styles={{
                    control: () => ({
                      padding: 1,
                      width: '100%',
                      borderRadius: 5,
                      display: 'flex',
                      border: '1px solid #d7d7d7',
                      marginBottom: 10,
                    }),
                  }}
                  placeholder="Email Sender"
                  value={selectedSender}
                  defaultOptions={senders}
                  isClearable
                  loadOptions={fetchSenders} // Function to fetch sequence options asynchronously
                  getOptionValue={(option) => option.id} // Define how to get the value of each option
                  getOptionLabel={(option) =>option.email|| option.microsoft_email || option.google_email||option.smtpImapEmail} // Define how to get the label of each option
                  onChange={(selected) => {
                    if (selected) {
                      setPage(0);
                      setSelectedSender(selected);
                      if (selected.flowchart) {
                        let newNodes = selected.flowchart.nodes?.filter(
                          (n) => n.group === 'outreach'
                        );
                        setNodes(newNodes);
                      }
                    } else {
                      setSelectedSender(null);
                      fetchSenders()
                      setNode('');
                    }
                  }}
                  noOptionsMessage={() => 'No Results, Type to Search'}
                />

                <div className="searchPeople rounded mb-2" style={{ height: 38 }}>
                  <input
                    type="text"
                    placeholder={'Email Address of Lead'}
                    value={email}
                    onChange={(e) => {setPage(0); setEmail(e.target.value.trim());}}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') fetchLogs();
                    }}
                  />
                </div>

                {/* select event type */}
                <Select
                  styles={{
                    control: () => ({
                      padding: 1,
                      width: '100%',
                      borderRadius: 5,
                      display: 'flex',
                      border: '1px solid #e5e5e5',
                      marginBottom: 10,
                    }),
                  }}
                  placeholder="Select Event Type"
                  value={(function () {
                    const events = [];

                    for (const event of selectedEvents) {
                      const eventFound = Object.entries(EVENT_TYPES).find(
                        ([key, value]) => value === event
                      );
                      if (eventFound)
                        events.push({ label: eventFound[0], value: eventFound[1] });
                    }

                    return events;
                  })()}
                  isClearable
                  isMulti
                  options={Object.entries(EVENT_TYPES).map(([label, value]) => ({
                    label,
                    value,
                  }))}
                  onChange={handleEventTypeSelect}
                />

                <CustomButton
                  title="Get Logs"
                  variant="primary"
                  style={{ width: '100%' }}
                  onClick={() => fetchLogs()}
                />
              </div>
            </div>
          </Row>
          {isLoading && (
            <div
              className="csFormLoader"
              style={{ background: `rgba(255, 255, 255, 0.5)` }}
            >
              <Loader />
            </div>
          )}
          {logs.length === 0 ? (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 'auto',
                  textAlign: 'center',
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="150"
                  height="150"
                  style={{ margin: 'auto' }}
                  viewBox="0 0 100 100"
                >
                  <circle cx="52" cy="52" r="44" opacity=".35"></circle>
                  <circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle>
                  <ellipse
                    cx="50.016"
                    cy="50.026"
                    fill="#d9eeff"
                    rx="38.017"
                    ry="38.026"
                  ></ellipse>
                  <path
                    fill="#70bfff"
                    d="M50.189,66.359c-3.776,0-6.66-1.4-8.651-4.203c-1.991-2.802-2.987-6.799-2.987-11.992 c0-5.191,0.996-9.152,2.987-11.881c1.991-2.728,4.875-4.093,8.651-4.093c3.776,0,6.659,1.365,8.651,4.093 c1.991,2.729,2.987,6.69,2.987,11.881c0,5.193-0.995,9.19-2.987,11.992C56.849,64.959,53.965,66.359,50.189,66.359z M50.189,60.784c1.711,0,2.979-0.847,3.806-2.544c0.825-1.696,1.239-4.387,1.239-8.076c0-3.687-0.414-6.342-1.239-7.964 c-0.826-1.622-2.095-2.435-3.806-2.435s-2.98,0.812-3.806,2.435c-0.826,1.622-1.239,4.277-1.239,7.964 c0,3.688,0.413,6.379,1.239,8.076C47.209,59.937,48.478,60.784,50.189,60.784z"
                  ></path>
                  <path
                    fill="#0080FF"
                    d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"
                  ></path>
                </svg>
                No logs found.
                <br />
                👈 Try updating filters.
              </div>
            </div>
          ) : (
            <>
              <CustomTable
                hasFooter={false}
                filter={search}
                columns={columns}
                data={logs}
                perPage={perPage}
                count={logsCount}
                onClick={(row) => {}}
                setPerPage={setPerPage}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
              <Tooltip place='top' anchorSelect='[data-tooltip-show="true"]'/>
              <Tooltip anchorSelect='[data-tooltip-timezone="true"]' place="top" />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
