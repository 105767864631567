import Axios from 'axios';
import CustomModal from 'components/custom/customModal';
import { Select, TextInput, Button, AuthContext } from 'components/lib';
import { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { UilQuestionCircle } from '@iconscout/react-unicons'
import { Tooltip } from 'react-tooltip';
import { getSubdomain } from 'tldts';
import './domaineditor.scss';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export default function DomainEditor(props) {
  const [domain, setDomain] = useState('');
  const [verified, setVerified] = useState(false);
  const [domainId, setDomainId] = useState(null);
  const [Type, setType] = useState('');
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (!props.selected) return;
    setDomain(props.selected.domain);
    setVerified(props.selected.verified);
    setDomainId(props.selected._id);
    setType(props.selected.type);
  }, [props.selected]);

  const handleSave = async () => {
    try {
      if (Type === '' || Type === 'unselected')
        return Toast.fire({ icon: 'error', title: `Domain type required.` });
      if (domain === '')
        return Toast.fire({ icon: 'error', title: `Domain required.` });
      
      let { data } = await Axios.post('/api/domains', { domain, type: Type });
      setDomainId(data.data._id);
      Toast.fire({ icon: 'success', title: `Domain saved successfully.` });
    } catch (err) {
      Toast.fire({ icon: 'error', title: `Error creating domain.` });
    }
  };

  const handleVerify = async () => {
    try {
      await Axios.put(`/api/domains/${domainId}/verify`);
      setVerified(true);
      Toast.fire({ icon: 'success', title: `Domain record verified.` });
    } catch (err) {
      Toast.fire({ icon: 'error', title: `${err?.response?.data?.message}` });
    }
  };

  const handleDisconnect = async () => {
    try {
      await Axios.put(`/api/domains/${domainId}/remove`);
      setVerified(false);
      Toast.fire({
        icon: 'success',
        title: `Domain disconnected sucessfully.`,
      });
      // props.onClose()
    } catch (err) {
      Toast.fire({ icon: 'error', title: `${err?.response?.data?.message}` });
    }
  };

  return (
    <>
      <CustomModal
        title={props.selected ? 'Edit Custom Domain' : 'Add Custom Domain'}
        onClose={props.onClose}
        close={true}
      >
        <div style={{ padding: 15 }}>
          <p
            className="text-modal-description"
            style={{ padding: 0 }}
          >
            {domainId ? Type === "meet-link" ? <div className='flex'>
              Connect your custom domain/subdomain to use it as Meeting Scheduler Link. 
              {authContext?.user?.permission !== 'client' && <a className='csCustomeLink justify-center cursor-pointer' href='https://help.salesblink.io/en/collections/8802293-meeting-scheduler' target='_blank'>
                <UilQuestionCircle size={18} style={{ marginRight: 3 }} />
                Learn How
              </a>}
            </div> : <div className='flex'>
              Custom domain can be used for tracking email opens and link clicks to improve deliverability.
              {authContext?.user?.permission !== 'client' && <a className='csCustomeLink justify-center cursor-pointer' href='https://help.salesblink.io/en/articles/9080625-custom-domains-for-email-tracking' target='_blank'>
                <UilQuestionCircle size={18} style={{ marginRight: 3 }} />
                Learn How
              </a>}
            </div>
            : "Custom domains/subdomains can be used to track emails (improves deliverability) or as meeting scheduler link."}
          </p>
          {!domainId && (
            <Select
              options={[
                { value: 'meet-link', label: 'Meeting Custom Domain/Subdomain' },
                {
                  value: 'click-tracker',
                  label: 'Email Tracking Custom Domain/Subdomain',
                },
              ]}
              placeholder="Select domain type"
              value={Type}
              onChange={(n, value) => {
                setType(value);
              }}
            />
          )}
          <div className='flex'>
            <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-600 after:text-red-500 after:ml-1 after:font-semibold after:content-['*']">
              Custom Domain or Sub-Domain
            </label>
            <UilQuestionCircle
              size={18}
              id={"custom-domain-help"}
              style={{ marginLeft: 3, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#custom-domain-help`} place="right">
              Add URL of your custom domain or subdomain here.
            </Tooltip>
          </div>
          <TextInput
            onChange={(name, value) => {
              if(!domainId) setDomain(value.replaceAll('https://','').replaceAll('http://',''))
            }}
            disabled={domainId}
            value={domain}
          />
          {domainId && !verified && (
            <div>
              <p style={{ margin: 10 }}>
                Please add the following details to your domain's DNS record and
                click Verify Record to continue.
              </p>
              <table className="domainTable">
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
                <tr>
                  <td>{getSubdomain(domain) === '' ? "A Record" : "CNAME Record"}</td>
                  <td>{domain}</td>
                  <td>
                    {(Type === 'click-tracker' && getSubdomain(domain) !== '') ? 'cname.salesblink.io' : ''}
                    {(Type === 'click-tracker' && getSubdomain(domain) === '') ? '24.144.64.22' : ''}
                    {Type === 'meet-link' ? 'meet.salesblink.io' : ''}
                  </td>
                </tr>
              </table>
              <p style={{ margin: 10 }}>
                Note: It can take upto 24 hours for your DNS records to update.
              </p>
            </div>
          )}
          <div style={{ width: '100%', textAlign: 'right', marginTop: 10 }}>
            {!domainId && !verified && (
              <Button
                action={() => handleSave()}
                variant="primary"
                text={props.selected ? 'Update' : 'Create'}
                className="ml-auto"
              />
            )}
            {domainId && !verified && (
              <Button
                text="Verify Record"
                variant="primary"
                action={() => handleVerify()}
                className="ml-auto"
              />
            )}
            {domainId && verified && (
              <Button
                text="Disconnect Domain"
                variant="primary red"
                action={() => handleDisconnect()}
                className="ml-auto"
              />
            )}
          </div>
        </div>
      </CustomModal>
    </>
  );
}
